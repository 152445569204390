import React from "react";
import styles from "./App.module.css";
import Media from "react-media";
import { MobileMenu, DesktopMenu } from "./Components/Menu/Menu";
import { Home } from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Services from "./Components/Services/Services";
import Address from "./Components/Addresses/Address";
import Footer from "./Components/Footer/Footer";
import ContactForm from "./Components/ContactForm/ContactForm";

function App() {
  return (
    <div className={styles["app"]}>
      <Media query={{ maxWidth: 1000 }}>
        <MobileMenu />
      </Media>
      <Media query={{ minWidth: 1001 }}>
        <DesktopMenu />
      </Media>
      <Home />
      <AboutUs />
      <Services />
      <Address />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
