import React from "react";
import styles from "./Services.module.css";
import Card from "./Card.jsx";
import servicesContent from "../../resources/content/servicesContent";

export default function Services() {
  return (
    <section id="services">
    <div className={styles["services"]}>
      <h1 className={styles["services-cards__title"]}>Áreas de Prática</h1>
      <div className={styles["services-cards"]}>
        {servicesContent.map((service) => {
          return (
            <Card
              imageSource={service.src}
              title={service.title}
              text={service.text}
            />
          );
        })}
      </div>
      <h4 className={styles["services-cards__text"]}>
        Assessoria Jurídica a Municípios, Autarquias, Empresas Públicas e
        Empresas Privadas.
        <br></br>
        Consultoria Privada & Pareceres Jurídicos.
      </h4>
      </div>
    </section>
  );
}
