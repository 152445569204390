import React from "react";
import styles from "./AboutUs.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Resources
import fabio from "../../resources/assets/people/fabio.jpg";
import simone from "../../resources/assets/people/simone.jpg";
import bento from "../../resources/assets/people/bento.jpeg";

export default function AboutUs() {
  let windowWidth = window.innerWidth;

  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "1",
    slidesToShow: windowWidth < 600 ? 1 : 3,
    speed: 300,
  };

  return (
    <section id="about-us">
      <div className={styles["about-us"]}>
        <h1 className={styles["about-us__title"]}>Quem Somos</h1>
        <p className={styles["about-us__text"]}>
          Proporcionamos um Advogado de proximidade, baseado na figura do Advogado-Confidente,
          apoiado numa estrutura sólida e diversificada no Direito. <br></br>Somos uma equipa jovem,
          multidisciplinar, com altos padrões éticos e deontológicos, de honestidade, competência,
          rigor e profissionalismo, a FVC Advogados pretende prestar um serviço de excelência ao
          nosso cliente, sendo o nosso principal foco aconselhar o nosso cliente naquilo que é o
          mais importante na defesa dos seus interesses e Direitos. <br></br> Atuamos em todo o
          território Nacional e Internacional, vocacionados para a assessoria e consultoria jurídica
          de particulares e Empresas privadas e públicas.
        </p>
        <Slider {...settings} className={styles["about-us__images"]}>
          <div>
            <img className={styles["about-us__image"]} src={fabio} alt="person"></img>
            <h5>Fábio Venâncio de Carvalho</h5>
            <h6>Advogado</h6>
          </div>
          <div>
            <img className={styles["about-us__image"]} src={simone} alt="person"></img>
            <h5>Simone da Costa Santos</h5>
            <h6>
              Advogada <br></br>
              <span>Externa</span>
            </h6>
          </div>
          <div>
            <img className={styles["about-us__image"]} src={bento} alt="person"></img>
            <h5>Ferreira Bento</h5>
            <h6>Consultor</h6>
          </div>
        </Slider>
        <div className={styles["about-us__partners"]}>
          <h6 className={styles["about-us__partners-title"]}>Parceria Internacional:</h6>
          <h6 className={styles["about-us__brasil-partner-title"]}>Flávia Martins Barbosa</h6>
          <h6 className={styles["about-us__brasil-partner-role"]}>Advogada</h6>
          <h6 className={styles["about-us__brasil-partner-location"]}>(Brasil)</h6>
        </div>
      </div>
    </section>
  );
}
