import React from "react";
import styles from "./Address.module.css";
import map from "../../resources/assets/contact/carcavelos-map.png";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Address = () => {
  return (
    <section id="address" className={styles["addresses"]}>
      <h1>Contactos</h1>

      <div className={styles["addresses__text-information"]}>
        <div className={styles["addresses__column"]}>
          <div>
            <h4 className={styles["addresses__label"]}>Cascais</h4>
            <h5>
              Rua Dr. José Joaquim de Almeida nº670<br></br>2775-594 Carcavelos,
              Portugal
            </h5>
          </div>

          <div>
            <h4 className={styles["addresses__label"]}>Algarve</h4>
            <h5>
              Rua Francisco José Nascimento Bateira Bloco A, 1B <br></br>
              8800-532 Santa Luzia, Tavira, Portugal
            </h5>
          </div>
        </div>

        <div className={styles["addresses__column"]}>
          <div>
            <h4 className={styles["addresses__label"]}>Telefone</h4>
            <h5>
              (+351) 214 038 862<br></br>(+351) 918 526 513
            </h5>
          </div>

          <div>
            <h4 className={styles["addresses__label"]}>Email</h4>
            <h5>fvc@fvcadvogados.com</h5>
            <a
              href="https://www.facebook.com/fvc.fvcadvogados"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook
              className={styles["addresses__social-icon"]}
                size="20"
              />
            </a>
            <a
              href="https://www.instagram.com/fvcadvogados_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                className={`${styles["addresses__social-icon"]} ${styles["addresses__instagram-icon"]}`}

                size="20"
              />
            </a>
          </div>
        </div>

        <div className={styles["addresses__map"]}>
          <a
            href="https://www.google.com/maps/place/R.+Dr.+Jos%C3%A9+Joaquim+de+Almeida+670,+2775-611+Carcavelos/@38.6903057,-9.3367422,19z/data=!4m13!1m7!3m6!1s0xd1ec8ce519260b5:0xa342b949e7479d56!2sR.+Dr.+Jos%C3%A9+Joaquim+de+Almeida+670,+2775-611+Carcavelos!3b1!8m2!3d38.6903057!4d-9.336195!3m4!1s0xd1ec8ce519260b5:0xa342b949e7479d56!8m2!3d38.6903057!4d-9.336195"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={map} alt="map"></img>
          </a>
        </div>
      </div>
    </section>
  );
};
export default Address;
