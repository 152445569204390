import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <h6>© FVC Advogados. Todos os Direitos Reservados.</h6>
    </div>
  );
}
