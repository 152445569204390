const servicesContent = [
  {
    title: "Direito do Trabalho",
    src:
      "https://www.whsc.on.ca/getmedia/f30a4f4f-5ebb-4c20-ba4a-172120e10768/03-26-19_E-Bulletin_SocialShare_Supportive-supervisors-reduce-risk-of-worker-injury-study-finds.jpg.aspx",
    text: [
      "Processos Disciplinares;",
      "Elaboração de Contratos de Trabalho;",
      "Elaboração de Adendas aos Contratos de Trabalho;",
      "Elaboração de Contratos de Prestação de Serviços;",
      "Acordos de Cessação de Contratos de Trabalho;",
      "Acordos de Pré-Reforma;",
      "Despedimentos Coletivos;",
      "Extinção de Posto de Trabalho;",
      "Procedimentos de Lay-off;",
      "Reclamações de Créditos Laborais (salários, horas extras não pagas, subsídios, férias, discriminação, assédio, retaliação, formação profissional, etc.);",
      "Ações de Impugnação de despedimento;",
      "Acidentes de Trabalho;",
      "Ações Judiciais;",
      "Consultoria e Assessoria na Gestão de Recursos Humanos.",
    ],
  },
  {
    title: "Direito Criminal",
    src: "https://www.dw.com/image/47316391_101.jpg",
    text: [
      "Contencioso Penal (Tráfico de Estupefacientes, Homicídio, Rapto, Sequestro, Lenocínio, Terrorismo, Abuso de Confiança, Furto, Roubo, Dano, Desobediência, Ameaça, Injúria, Difamação, Ofensas à Integridade Física, Violência Doméstica, Abuso Sexual, Burla, Extorsão, Falsificação, etc.);",
      "Elaboração de Queixas-Crime;",
      "Requerimentos de Abertura de Instrução (RAI);",
      "Recursos Hierárquicos;",
      "Acordos de Cessação de Contratos de Trabalho;",
      "Acusações Particulares;",
      "Pedido de Indeminização Cível (PIC)",
      "Acompanhamento ao cliente em inquirições/interrogatórios judiciais nos OPC;",
      "Cancelamento de Registo Criminal;",
      "Recursos Judiciais;",
      "Consultoria e Assessoria Jurídica Criminal a clientes particulares e societários;",
    ],
  },
  {
    title: "Contraordenações (multas)",
    src:
      "https://images.pexels.com/photos/288476/pexels-photo-288476.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Rodoviárias;",
      "Laborais;",
      "Fiscais;",
      "Imobiliárias & Construção;",
      "Ambientais;",
      "Marítimas;",
      "Aeronáuticas.",
    ],
  },
  {
    title: "Direito do Desporto",
    src:
      "https://images.pexels.com/photos/274506/pexels-photo-274506.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Elaboração de Contratos de Trabalho Desportivo;",
      "Elaboração de Contratos de Representação de Jogadores e Atletas;",
      "Negociação de Contratos de Trabalho Desportivo;",
      "Contencioso e Arbitragem de conflitos de natureza Desportiva junto dos órgãos federativos e judiciais, nacionais e estrangeiros;",
      "Direitos de imagem associados ao Desporto;",
      "Assessoria Jurídica a Jogadores, Atletas, Clubes, Sociedades Desportivas, Intermediários ou Empresas na Área do Desporto;",
      "Assessoria a Federações e Associações Desportivas de diversas modalidades;",
      "Consultoria e Assessoria Geral a Clubes Desportivos.",
    ],
  },
  {
    title: "Direito Imobiliário & Construção",
    src:
      "https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Elaboração de Contratos Promessa Compra e Venda (CPCV);",
      "Elaboração de Contratos de transmissão e oneração de bens imoveis (Escrituras);",
      "Elaboração de Contratos de Empreitada;",
      "Consultoria a projetos de aquisição e venda de bens imóveis destinados a habitação, comércio e indústria;",
      "Acompanhamento na celebração e execução de contratos de arrendamento e outros;",
      "Apoio nos processos de concessão de licenças e alvarás de loteamento;",
      "Aquisição, licenciamento, construção, promoção e venda de bens imobiliários;",
      "Empreitadas de obras públicas e dos fornecimentos, prestando assistência nos procedimentos de formação dos contratos;",
      "Processos de licenciamento (de unidades comerciais, industriais e turísticas);",
      "Assessoria jurídica a empresas imobiliárias e de promoção e gestão de patrimónios em todas as suas áreas de negócio.",
    ],
  },
  {
    title: "Direito Civil & Contencioso Civil",
    src:
      "https://d3lp4xedbqa8a5.cloudfront.net/s3/digital-cougar-assets/whichcar/2019/06/28/4621/crash-wde.jpg-w.jpg",
    text: [
      "Responsabilidade Civil Contratual e Extracontratual;",
      "Acidentes Rodoviários (Sinistros);",
      "Defesa do Consumidor (Viagens, Direitos dos Contratos de Adesão, Garantias de Bens de Consumo);",
      "Direitos Reais;",
      "Providências Cautelares;",
      "Usucapião;",
      "Posse;",
      "Ações de Despejo;",
      "Penhoras;",
      "Hipotecas;",
      "Assessoria e negociação pré-contencioso, contencioso arbitral e judicial.",
    ],
  },
  {
    title: "Direito da Família & Sucessório",
    src:
      "https://images.pexels.com/photos/1166990/pexels-photo-1166990.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500h",
    text: [
      "Processos de Divórcio;",
      "Regulação das Responsabilidades Parentais;",
      "Pensão de Alimentos;",
      "Heranças e Partilhas;",
      "Administração da Herança;",
      "Habilitação de Herdeiros;",
      "Processos de Inventário;",
      "Testamentos;",
      "Doações;",
      "Ações de Inabilitação e Interdição;",
      "Consultoria e Assessoria Familiar.",
    ],
  },
  {
    title: "Direito Comercial & Societário",
    src:
      "https://images.pexels.com/photos/3740400/pexels-photo-3740400.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Constituição e Estruturação de Empresas;",
      "Alterações na Sociedade;",
      "Elaboração de Estatutos, Atas e Pactos Sociais;",
      "Aumentos de Capital;",
      "Cessão de Quotas;",
      "Fusões, Cisões e Aquisições;",
      "Dissoluções;",
      "Elaboração de Registo Central do Beneficiário Efectivo (RCBE);",
      "Assessoria comercial e corporativa e acompanhamento da vida societária em geral.",
    ],
  },
  {
    title: "Propriedade Intelectual",
    src:
      "https://poletto.adv.br/wp-content/uploads/2020/06/Foto-03-2.jpg",
    text: [
      "Registo de Marcas, Patentes, Desenhos e Modelos Industriais, Logótipos, Firmas Comerciais e Denominações Sociais;",
      "Proteção de Dados;",
      "Direitos de Autor, know-how, proteção de dados confidenciais e de programas informáticos;",
      "Concorrência Desleal.",
    ],
  },
  {
    title: "Insolvências, Recuperação de Créditos & Revitalização de Empresas",
    src: "https://live.staticflickr.com/810/40144693214_8cd3004bca_b.jpg",
    text: [
      "Definição e implementação de Planos de Insolvência Singular e Coletiva;",
      "Definição estratégica e patrocínio judiciário em Processos Especiais de Revitalização (PER’s) e Processos de Insolvência;",
      "Negociação com credores e devedores;",
      "Cobrança de Dívidas e Recuperação Judicial de Créditos;",
      "Injunções;",
      "Execuções;",
      "PEPEX.",
    ],
  },
  {
    title: "Direito Bancário e Seguros",
    src:
      "https://images.pexels.com/photos/259191/pexels-photo-259191.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Preparação, negociação e formalização de contratos bancários;",
      "Aconselhamento no financiamento e promoção de projetos de desenvolvimento imobiliário;",
      "Intermediação Financeira;",
      "Pedidos de NIF;",
      "Gestão de Ativos;",
      "Mercado de Capitais;",
      "Restruturação de créditos;",
      "Recuperação de créditos;",
      "Acidentes de Viação;",
      "Acidentes de Trabalho;",
      "Reclamação de indeminização a Seguradoras;",
      "Assessoria a empresas de seguros e mediadores de seguros.",
    ],
  },
  {
    title: "Investement",
    src:
      "https://images.pexels.com/photos/534216/pexels-photo-534216.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Aquisição de bens imóveis;",
      "Planeamento, preparação e aconselhamento sobre veículos de investimento imobiliário;",
      "Operações de investimento turístico, acompanhando a instalação, a exploração e o funcionamento de empreendimentos turísticos;",
      "Comércio nacional e internacional;",
      "Exportações;",
      "Mercados de capitais, fundos de investimento, gestão e composição de ativos;",
      "Consultoria e Assessoria na gestão de investimentos.",
    ],
  },
  {
    title: "Imigração e Vistos Golden Visa",
    src:
      "https://images.pexels.com/photos/113885/pexels-photo-113885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    text: [
      "Aconselhamento e revisão da documentação necessária para instruir Processo de Nacionalidade junto da Conservatória do Registo Civil;",
      "Instrução de Processo de Nacionalidade junto da Conservatória do Registo Civil;",
      "Aconselhamento e revisão da documentação necessária para instruir o Processo de Autorização de Residência junto do SEF – Serviço de Estrangeiros e Fronteiras;",
      "Acompanhamento do cliente e familiares ao Serviço de Estrangeiros e Fronteiras (SEF);",
      "Assistência a estrangeiros em Portugal;",
      "Pedidos de NIF;",
      "Revisão de Sentença Estrangeira;",
      "Programas de ‘Investment’ para adquirir o Visto Golden Visa.",
    ],
  },
  {
    title: "Elaboração de Contratos",
    src:
      "https://images.pexels.com/photos/3771097/pexels-photo-3771097.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Contratos de Promessa Compra e Venda;",
      "Contratos de Arrendamento;",
      "Contratos de Trespasse;",
      "Contratos de Comodato;",
      "Contratos de Franquia;",
      "Contratos de Trabalho;",
      "Contratos de Cedência Ocasional de Trabalhador;",
      "Contratos de Prestação de Serviços;",
      "Contratos de Empreitada;",
      "Contratos de Cessão de Exploração;",
      "Assessoria ao Cliente em qualquer tipo de contrato.",
    ],
  },
  {
    title: "Registos & Notariado",
    src:
      "https://images.pexels.com/photos/357514/pexels-photo-357514.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    text: [
      "Certidão de Óbito;",
      "Certidão de Nascimento;",
      "Registo Criminal;",
      "Registo Automóvel;",
      "Reconhecimento de Dívida;",
      "Contratos de Transmissão e Oneração de Bens Imóveis (Escrituras);",
      "Certificação de Fotocópias;",
      "Reconhecimento de Assinaturas;",
      "Autenticação de Documentos;",
      "Procurações Forenses e Especiais;",
      "Testamentos.",
    ],
  },
];

export default servicesContent;
