import React, { useState } from "react";
import "./Card.css";
import { motion } from "framer-motion";

export default function Card(props) {
  const [isOpen, setIsOpen] = useState(null);

  const cardVariants = {
    open: {
      height: "100%",
    },
    closed: {
      height: "100%",
      color: "black",
    },
  };

  return (
    <motion.div
      className="card"
      variants={cardVariants}
      onTap={() => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
      }}
      animate={isOpen ? "open" : "closed"}
      initial={false}
    >
      <img className="card-image" src={props.imageSource} alt=""></img>
      {props.title === "Investement" ? (
        <h4 className="title investement-title">
          <i>'{props.title}'</i>
        </h4>
      ) : (
        <h4 className="title">{props.title}</h4>
      )}
      {isOpen && (
        <ul className="fields-ul">
          {props.text.map((element) => (
            <li>{element}</li>
          ))}
        </ul>
      )}
    </motion.div>
  );
}
