import React from "react";
import styles from "./TopBar.module.css";
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";

export default function TopBar() {
  return (
    <div className={styles["top-bar"]}>
      <a className={styles["top-bar__element"]}>
        <FaRegEnvelope className={styles["top-bar__contact-icon"]} size="15" />
        fvc@fvcadvogados.com
      </a>
      <a className={styles["top-bar__element"]}>
        <FaPhoneAlt className={styles["top-bar__contact-icon"]} size="15" />{" "}
        +351 918 526 513
      </a>
      <a
        href="https://www.facebook.com/fvc.fvcadvogados"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook size="18" className={styles["top-bar__icon"]} />
      </a>
      <a
        href="https://www.instagram.com/fvcadvogados_/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram size="18" className={styles["top-bar__icon"]} />
      </a>
    </div>
  );
}
