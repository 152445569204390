import React, { useState } from "react";
import styles from "./ContactForm.module.css";
import { RiCheckLine } from "react-icons/ri";
import emailjs from "emailjs-com";

export default function ContactForm() {
  const [isCorrect, setIsCorrect] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "Gmail",
        "template_0pdyway",
        e.target,
        "user_Gw8lhRJgMREhq6kXzJHZZ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsCorrect(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <form onSubmit={sendEmail}>

      <div className={styles["contacts"]}>

        <div className={styles["contacts__picture"]}></div>

        {isCorrect ? (
          <div className={styles["contacts__message-container"]}>
            <RiCheckLine size="50" className={styles["contacts__check-icon"]}/>
            <h5 className={styles["contacts__submit-message"]}>
              Agradecemos o seu contacto. <br></br>Receberá um email de resposta com a maior brevidade possivel.
            </h5>
            <a className={styles["contacts__voltar-button"]} href="#home" onClick={() => window.location.reload()}>Voltar ao início</a>
            </div>
        ) : (


          <div className={styles["contacts__form"]}>
          <div>
            <h4>Entre em contacto connosco</h4>
            <p>Preencha o formulário de contacto e aguarde pelo email de resposta.</p>
            </div>
            <input className={styles["contacts__input"]} type="text" name="name" placeholder="Nome" />
            <input className={styles["contacts__input"]} type="text" name="email" placeholder="Email" />
            <input className={`${styles["contacts__input"]} ${styles["contacts__text-area"]}`} type="text" name="message" placeholder="Mensagem" />
            <button className={styles["contacts__button"]} type="submit">Enviar</button>
          </div>

        )}
      </div>

    </form>
  );
}
