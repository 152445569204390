import React from "react";
import styles from "./Home.module.css";
import { motion } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


export function Home() {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const renderThumbs = () => {
    return false;
  };

  return (
    <motion.div id="home" initial="hidden" animate="visible" variants={variants} transition={{ease:"easeOut", duration: 2}}>
    <Carousel renderThumbs={renderThumbs}>
      <div className={`${styles["home__slide"]} ${styles["home__slide-one"]}`}>
        <h1 className={styles["home__slogan"]}>Honestidade, competência, rigor e profissionalismo.</h1>
        <h3>Os seus parceiros de confiança.</h3>
      </div>
      <div className={`${styles["home__slide"]} ${styles["home__slide-two"]}`}>
        <h1 className={styles["home__slogan"]}>A Excelência do Direito, para um Direito de Excelência.</h1>
      </div>
    </Carousel>
    </motion.div>
  );
}
