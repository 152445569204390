import React, { useState } from "react";
import styles from "./Menu.module.css";
import { motion } from "framer-motion";
import $ from "jquery";

//Components
import TopBar from "./TopBar/TopBar";

//Resources
import logo from "../../resources/assets/logo/logo.gif";
import { FaBars } from "react-icons/fa";

export function DesktopMenu() {
 
  return (
    <div className={styles["menu"]}>
      <TopBar />
      <div className={styles["menu__container"]}>
        <a href="#home">
          <img
            className={styles["menu__logo"]}
            src={logo}
            alt="backgroundPicture"
          ></img>
        </a>
        <div className={styles["menu__options"]}>
          <a href="#home" className={`${styles["menu__item"]} `}>
            HOME
          </a>
          <a href="#about-us" className={styles["menu__item"]}>
            QUEM SOMOS
          </a>

          <a href="#services" className={styles["menu__item"]}>
            ÁREAS DE PRÁTICA
          </a>

          <a href="#address" className={styles["menu__item"]}>
            CONTACTOS
          </a>
        </div>
      </div>
    </div>
  );
}

export function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const menu_variants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -300 },
  };

  return (
    <div className={styles["mobile-menu"]}>
      <motion.div initial="hidden" animate={isOpen ? "open" : "hidden"}>
        <a href="#home">
          <img
            className={styles["mobile-menu__logo"]}
            src={logo}
            alt="backgroundPicture"
          ></img>
        </a>
        <motion.div
          className={styles["mobile-menu__options"]}
          variants={menu_variants}
        >
          <a
            className={styles["mobile-menu__item"]}
            href="#home"
            onClick={() => setIsOpen(!isOpen)}
          >
            HOME
          </a>
          <a
            className={styles["mobile-menu__item"]}
            href="#about-us"
            onClick={() => setIsOpen(!isOpen)}
          >
            QUEM SOMOS
          </a>
          <a
            className={styles["mobile-menu__item"]}
            href="#services"
            onClick={() => setIsOpen(!isOpen)}
          >
            ÁREAS DE PRÁTICA
          </a>
          <a
            className={styles["mobile-menu__item"]}
            href="#address"
            onClick={() => setIsOpen(!isOpen)}
          >
            CONTACTOS
          </a>
        </motion.div>
      </motion.div>
      <button onClick={() => setIsOpen(!isOpen)}>
        <FaBars fontSize="30" color="grey" />
      </button>
    </div>
  );
}
